import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const Logout = () => {
  const [foo, removeCookie] = useCookies(['jolly']);
  console.log(foo);

  const handleRemove = () => {
    removeCookie('jolly', { path: '/' });
  };

  useEffect(() => {
    handleRemove();
  });

  return <div style={{ flexGrow: '1' }}>You have been logged out</div>;
};

export default Logout;
