import * as React from 'react';
import { visuallyHidden } from '@mui/utils';
import _ from 'lodash';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Tooltip from '@mui/joy/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import CircularProgress from '@mui/joy/CircularProgress';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@mui/joy';
import {
  fetchCGNAT,
  QueryInput,
  Record,
  selectLogs,
} from './features/cgnat/cgnat';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { selectToken } from './features/me/me';
import { RootState } from './app/store';
import Middle from './containers/Middle';
import { ipAddrRegex } from './pattern';

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export const setEmptyOrStr = (v: unknown) => {
  if (_.isString(v) && _.isEmpty(v)) return undefined;
  return v;
};

export const getBeforeDate = () => {
  const beforeDate = new Date();
  beforeDate.setDate(beforeDate.getDate() - 1);
  return beforeDate.toISOString().split('.')[0];
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof Record>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Record;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'time_seconds',
    numeric: false,
    disablePadding: true,
    label: 'Timestamp',
  },
  {
    id: 'src_addr',
    numeric: true,
    disablePadding: false,
    label: 'Source IP',
  },
  {
    id: 'post_nat_src_addr',
    numeric: true,
    disablePadding: false,
    label: 'Post NAT Source IP',
  },
  {
    id: 'dst_addr',
    numeric: true,
    disablePadding: false,
    label: 'Destination IP',
  },
  {
    id: 'src_port',
    numeric: true,
    disablePadding: false,
    label: 'Source Port',
  },
  {
    id: 'post_nat_src_port',
    numeric: true,
    disablePadding: false,
    label: 'Post NAT Src Port',
  },
  {
    id: 'dst_port',
    numeric: true,
    disablePadding: false,
    label: 'Destination Port',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Record,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: keyof Record) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <thead>
      <tr>
        <th>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            slotProps={{
              input: {
                'aria-label': 'select all desserts',
              },
            }}
            sx={{ verticalAlign: 'sub' }}
          />
        </th>
        {headCells.map((headCell) => {
          const active = orderBy === headCell.id;
          return (
            <th
              key={headCell.id}
              aria-sort={
                active
                  ? ({ asc: 'ascending', desc: 'descending' } as const)[order]
                  : undefined
              }
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                underline="none"
                color="neutral"
                textColor={active ? 'primary.plainColor' : undefined}
                component="button"
                onClick={createSortHandler(headCell.id)}
                fontWeight="lg"
                startDecorator={
                  headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                endDecorator={
                  !headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                sx={{
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && order === 'desc'
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                  },
                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        py: 1,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: 'background.level1',
        }),
        borderTopLeftRadius: 'var(--unstable_actionRadius)',
        borderTopRightRadius: 'var(--unstable_actionRadius)',
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} component="div">
          {numSelected}
          {' '}
          selected
        </Typography>
      ) : (
        <Typography
          level="body-lg"
          sx={{ flex: '1 1 100%' }}
          id="tableTitle"
          component="div"
        >
          CGNAT Logs
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton size="sm" color="danger" variant="solid">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton size="sm" variant="outlined" color="neutral">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

const TableSortAndSelection = () => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Record>('src_addr');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const cgnatState = useAppSelector((state: RootState) => state.cgnat);
  const rows = useAppSelector(selectLogs);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Record,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.time_seconds);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows.length === -1) {
      return (page + 1) * 20;
    }
    return Math.min(rows.length, (page + 1) * 20);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * 20 - rows.length) : 0;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<QueryInput>();

  console.log(errors);

  const onSubmit: SubmitHandler<QueryInput> = (data) => {
    console.log(data);
    dispatch(
      fetchCGNAT({
        ...data,
        token,
        output: 'json',
        limit: 100,
      } as QueryInput),
    );
  };

  if (cgnatState.status === 'loading') {
    return (
      <Middle outlet={<CircularProgress style={{ color: '#8c2b25' }} />} />
    );
  }

  console.log(new Date().toISOString());
  return (
    <>
      <Box padding={5}>
        <Stack>
          <FormControl>
            <FormLabel>Post NAT Source IP</FormLabel>
            <Input
              {...register('src_ip', {
                setValueAs: setEmptyOrStr,
                pattern: {
                  value: ipAddrRegex,
                  message:
                    'invalid ip address either ipv4 or ipv6 are accepted',
                },
              })}
              placeholder="input ip address, e.g. 100.127.228.18"
            />
            {errors.src_ip && (
            <div className="text-info">
              {errors.src_ip.message}
            </div>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Destination IP</FormLabel>
            <Input
              {...register('dst_ip', {
                setValueAs: setEmptyOrStr,
                pattern: {
                  value: ipAddrRegex,
                  message:
                    'invalid ip address either ipv4 or ipv6 are accepted',
                },
              })}
              placeholder="input ip address, e.g. 1.1.1.1"
            />
            {errors.dst_ip && (
            <div className="text-info">
              {errors.dst_ip.message}
            </div>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Post NAT Source Port</FormLabel>
            <Input
              {...register('src_port', {
                setValueAs: setEmptyOrStr,
                pattern: {
                  value: /^[0-9]{1,6}/,
                  message: 'port number must be a number',
                },
              })}
              placeholder="example: 123"
            />
            {errors.src_port && (
            <div className="text-info">
              {errors.src_port.message}
            </div>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Destination Port</FormLabel>
            <Input
              {...register('dst_port', {
                setValueAs: setEmptyOrStr,
                pattern: {
                  value: /^[0-9]{1,6}/,
                  message: 'dst port number must be a number',
                },
              })}
              placeholder="example 80"
            />
            {errors.dst_port && (
            <div className="text-info">
              {errors.dst_port.message}
            </div>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>From</FormLabel>
            <Input
              {...register('start_timestamp', {
                setValueAs: (value: string) => new Date(value).getTime() / 1000,
                required: 'from time is required',
              })}
              type="datetime-local"
              defaultValue={getBeforeDate()}
              slotProps={{
                input: {
                  min: '2023-01-01T00:00',
                },
              }}
            />
            {errors.start_timestamp && (
            <div className="text-info">
              {errors.start_timestamp.message}
            </div>
            )}

          </FormControl>

          <FormControl>
            <FormLabel>To</FormLabel>
            <Input
              {...register('end_timestamp', {
                setValueAs: (value: string) => new Date(value).getTime() / 1000,
                required: 'to time is required',
              })}
              type="datetime-local"
              defaultValue={new Date().toISOString().split('.')[0]}
              slotProps={{
                input: {
                  min: '2023-01-01T00:00',
                },
              }}
            />
            {errors.end_timestamp && (
            <div className="text-info">
              {errors.end_timestamp.message}
            </div>
            )}
          </FormControl>
        </Stack>

        <br />
        <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
      </Box>

      <Box display="flex" alignItems="center" padding={5}>
        <Sheet
          variant="outlined"
          sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
        >
          <EnhancedTableToolbar numSelected={selected.length} />
          <Table
            aria-labelledby="tableTitle"
            hoverRow
            sx={{
              '--TableCell-headBackground': 'transparent',
              '--TableCell-selectedBackground': (theme) => theme.vars.palette.success.softBg,
              '& thead th:nth-child(1)': {
                width: '30px',
              },
              '& thead th:nth-child(2)': {
                width: '30%',
              },
              '& tr > *:nth-child(n+3)': { textAlign: 'right' },
            }}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <tbody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * 20, page * 20 + 20)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.time_seconds);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <tr
                      onClick={(event) => handleClick(event, row.time_seconds)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.time_seconds}
                      // selected={isItemSelected}
                      style={
                        isItemSelected
                          ? ({
                            '--TableCell-dataBackground':
                                'var(--TableCell-selectedBackground)',
                            '--TableCell-headBackground':
                                'var(--TableCell-selectedBackground)',
                          } as React.CSSProperties)
                          : {}
                      }
                    >
                      <th scope="row">
                        <Checkbox
                          checked={isItemSelected}
                          slotProps={{
                            input: {
                              'aria-labelledby': labelId,
                            },
                          }}
                          sx={{ verticalAlign: 'top' }}
                        />
                      </th>
                      <th id={labelId} scope="row">
                        {row.time_seconds}
                      </th>
                      <td>{row.src_addr}</td>
                      <td>{row.post_nat_src_addr}</td>
                      <td>{row.dst_addr}</td>
                      <td>{row.src_port}</td>
                      <td>{row.post_nat_src_port}</td>
                      <td>{row.dst_port}</td>
                    </tr>
                  );
                })}
              {emptyRows > 0 && (
                <tr
                  style={
                    {
                      height: `calc(${emptyRows} * 40px)`,
                      '--TableRow-hoverBackground': 'transparent',
                    } as React.CSSProperties
                  }
                >
                  <td colSpan={6}>&nbsp;</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows.length === 0 ? 0 : page * 20 + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows.length === -1 ? -1 : rows.length,
                      })}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: 'background.surface' }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows.length !== -1
                            ? page >= Math.ceil(rows.length / 20) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: 'background.surface' }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Sheet>
      </Box>
    </>
  );
};

export default TableSortAndSelection;
