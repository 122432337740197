import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface CGNAT {
  authenticate: string;
  token: string | undefined;
}

export interface QueryInput {
  start_timestamp: number;
  end_timestamp: number;
  src_ip: string;
  dst_ip: string;
  src_port: number;
  dst_port: number;
  proto: number;
  token: string;
  output: string;
  limit: number;
}

export interface cgnatState {
  value: Record[];
  status: 'idle' | 'loading' | 'failed';
}

export interface Record {
  time_seconds: string;
  src_addr: string;
  dst_addr: string;
  post_nat_src_addr: string;
  src_port: number;
  dst_port: number;
  post_nat_src_port: number;
  proto: number;
}

const initialState: cgnatState = {
  value: [],
  status: 'idle',
};

export const fetchCGNAT = createAsyncThunk(
  'cgnat/fetchCGNAT',
  async (query: QueryInput) => {
    const response = await Client.post('/api/v1/cgnat', query, {
      headers: {
        Authorization: `Bearer ${query.token}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const cgnatSlice = createSlice({
  name: 'cgnat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCGNAT.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCGNAT.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchCGNAT.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectLogs = (state: RootState) => state.cgnat.value;

export default cgnatSlice.reducer;
