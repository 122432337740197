import React, { useEffect } from 'react';
import '@fontsource/inter';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/joy/CssBaseline';
import CircularProgress from '@mui/joy/CircularProgress';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import App from './App';
import ProtectedRoute from './components/ProtectedRoute';
import { fetchMe } from './features/me/me';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { RootState, store } from './app/store';
import Middle from './containers/Middle';
import Login from './components/Login';
import Logout from './components/Logout';

const container = document.getElementById('root')!;
const root = createRoot(container);

const Router = () => {
  const meState = useAppSelector((state: RootState) => state.me);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchMe(meState.value.token));
  }, [meState.value.authenticate]);

  if (meState.status === 'loading') {
    return (
      <Middle outlet={<CircularProgress style={{ color: '#8c2b25' }} />} />
    );
  }

  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={(
            <ProtectedRoute
              auth={meState.value.authenticate === 'success'}
              authenticationPath="/login"
              outlet={<App />}
            />
          )}
        >
          <Route path="/" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
